<h1>Mitgliedschaft</h1>
<p>
  Willkommen bei unserem Verein! Unsere Mitgliedschaftspreise sind fair und
  transparent, um jedem die Möglichkeit zu geben, Teil unserer Gemeinschaft zu
  werden.
</p>

<table class="table table-bordered">
  <tbody>
    <tr>
      <th scope="row">Passives Mitglied</th>
      <td>25 Euro</td>
    </tr>
    <tr>
      <th scope="row">Kinder & Jugendliche (&lt;14 Jahre)</th>
      <td>40 Euro</td>
    </tr>
    <tr>
      <th scope="row">Jugendliche (14-18 Jahre)</th>
      <td>50 Euro</td>
    </tr>
    <tr>
      <th scope="row">Schüler & Studenten (bis 25 Jahre)</th>
      <td>50 Euro</td>
    </tr>
    <tr>
      <th scope="row">1 Erwachsener</th>
      <td>120 Euro</td>
    </tr>
    <tr>
      <th scope="row">2 Erwachsene</th>
      <td>175 Euro</td>
    </tr>
    <tr>
      <th scope="row">Familie</th>
      <td>195 Euro</td>
    </tr>
  </tbody>
</table>
<hr />
<section>
  <h1>Arbeitsstunden</h1>
  <p>8 Arbeitsstunden jährlich sind bei aktiver Mitgliedschaft ab 12 Jahren zu leisten.</p>
  <p>(Alternativ werden 10 Euro pro nicht geleisteter Arbeitsstunde eingezogen)</p>
  <style>
    h1 {
      margin-top: 0;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 0px !important;
    }
    @media (min-width: 1300px) {
      h2 {
        font-size: 4em;
      }
    }
  </style>
</section>
<style>
  table {
    margin-bottom: 10px;
  }
  th,
  td {
    text-align: left;
    width: 50%;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 10px;
  }

  @media (min-width: 1300px) {
    table {
      font-size: 160%;
    }
  }
</style>
