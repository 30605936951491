<div class="container">
    <h1>Satzung</h1>
    <p>des TC grün-weiß Amorbach 1901 e.V</p>
    <br />
    <pre>
    <h2>§ 1 Name und Sitz des Vereins</h2>
    Der  Verein führt den Namen Tennisclub Grün-Weiß Amorbach 1901 e.V. und hat  seinen Sitz in Amorbach. Er ist im Vereinsregister eingetragen.
     
    <h2>§ 2 Zweck des Vereins</h2>
    Der  Verein verfolgt ausschließlich und unmittelbar gemeinnützige Zwecke im  Sinne des Abschnitts „Steuerbegünstigte Zwecke“ der Abgabenordnung.
    Zweck des Vereins ist die Förderung des Tennissportes.
    Der  Satzungszweck wird verwirklicht insbesondere durch Förderung  sportlicher Übungen und Leistungen seiner Mitglieder, insbesondere der  Jugend, die Errichtung und Unterhaltung der nötigen Sportanlagen.
    Der Tennisclub ist selbstlos tätig und verfolgt nicht in erster Linie eigenwirtschaftliche Zwecke.
    Mittel  des Vereins dürfen nur für die satzungsgemäßen Zwecke verwendet werden.  Die Mitglieder erhalten keine Zuwendungen aus Mitteln des Vereins.
    Der Verein verfolgt keine politischen Ziele und ist parteipolitisch, konfessionell und rassistisch neutral.
    Die  Mitarbeit im Verein erfolgt ehrenamtlich und unentgeltlich. Keine  Person darf durch Ausgaben, die dem Vereinszweck fremd sind oder durch  unverhältnismäßig hohe Vergütungen begünstigt werden.
    
    <h2>§ 3 Verbandszugehörigkeit</h2>  
    Der Verein ist Mitglied im Bayerischen Tennis-Verband (BTV) und dadurch auch Mitglied im Bayerischen  Landessport-Verband (BLSV).
    Er kann, soweit es seinen Zielen und Zwecken förderlich ist, sich weiteren Verbänden dieser Art anschließen.
    
    <h2>§ 4 Geschäftsjahr</h2>
    Das Geschäftsjahr beginnt am 1.1. und endet am 31.12. jeden Jahres (Kalenderjahr).
     
    <h2>§ 5 Mitglieder</h2>
    Die Mitglieder des Tennisclub Grün-Weiß Amorbach 1901 e.V. setzen sich zusammen aus:
    a)    Aktive Mitglieder über 18 Jahren
    b)    Aktive Mitglieder unter 18 Jahren (Schüler und Jugendliche)
    c)    Passive Mitglieder
    d)    Ehrenmitglieder
    Die  Zahl der Mitglieder ist grundsätzlich unbeschränkt. Sie kann jedoch  durch mehrheitlichen Vorstandsbeschluss zeitlich eingeschränkt werden,  wenn die vorhandenen Sporteinrichtungen nicht mehr ausreichen, einen  ordnungsgemäßen Sportbetrieb aufrecht zu erhalten (Aufnahmestopp).
     
    <h2>§ 6 Mitgliedschaft</h2>
    Mitglied  im Tennisclub kann jede männliche oder weibliche Person werden, die  bereit ist, aktiv und ehrenamtlich an den Vereinszielen mitzuarbeiten.
    Zu  Ehrenmitgliedern können Personen ernannt werden, die sich um den Verein  besonders verdient gemacht haben. Der Beschluss erfolgt durch die  Generalversammlung.
    Der Antrag auf Mitgliedschaft ist schriftlich an den Vorstand zu stellen.
    Bei Aufnahme ist die von der Generalversammlung festgelegte einmalige Aufnahmegebühr zu entrichten.
    
    <h2>§ 7 Rechte und Pflichten</h2>
    Mitglieder  über 18 Jahre und Ehrenmitglieder haben im Verein gleiche Rechte und  Pflichten. Sie können an allen öffentlichen Versammlungen mit vollem  Stimmrecht teilnehmen.
    Jedes  Mitglied ab 12 Jahren, Ausnahme Ehrenmitglieder und passive Mitglieder,  ist verpflichtet, Arbeitsstunden zu leisten. Bei Nichtableistung  erfolgt eine Entgeltzahlung. Die Anzahl der Stunden und die Höhe der  Entgeltzahlung bestimmt die GV mit einfacher Stimmenmehrheit.
    Fühlt  sich ein Mitglied in Vereinsangelegenheiten benachteiligt oder  persönlich betroffen, kann es sich schriftlich an den Vorstand wenden  und Abhilfe verlangen.
    Der Vorstand ist verpflichtet, begründete Anträge dieser Art rasch zu erledigen und Abhilfe zu schaffen.
    Die Pflichten der Mitglieder ergeben sich aus § 6 Satz 1.
    § 8 Ende der Mitgliedschaft
    Die Mitgliedschaft erlischt
    a)    durch Tod
    b)    durch Austritt
    c)    durch Ausschluss
    Der Austritt kann jederzeit schriftlich an den Vorstand erklärt werden.
    Der  Ausschluss kann ausgesprochen werden, wenn ein Mitglied in erheblichem  Maße gegen die Vereinsinteressen verstoßen oder in sonstiger Weise das  Ansehen des Vereins geschädigt hat oder trotz zweifacher Mahnung länger  als sechs Monate mit der Beitragszahlung im Rückstand ist. Die  Beitragspflicht erlischt im Falle des Austrittes, bzw. des Ausschlusses  mit Ende des Jahres, in dem der Austritt erklärt oder der Ausschluss  wirksam geworden ist.
    Der Ausschluss kann von jedem Vorstandsmitglied schriftlich beantragt werden.
    Der  Beschluss ist vom Vorstand mit 2 / 3 – Mehrheit seiner Mitglieder  geheim zu fassen und dem Betroffenen mit kurzer Begründung per  Einschreibebrief zuzustellen.
    Vor der Beschlussfassung soll das betroffene Mitglied gehört werden.
    Ein ausscheidendes Mitglied hat keinerlei Ansprüche auf das Vereinsvermögen.
    
    <h2>§ 9 Beitragspflicht</h2>
    Jedes Mitglied ist verpflichtet, den von der Generalversammlung festgesetzten Beitrag jährlich im Voraus zu bezahlen.
    Die  Höhe der Beiträge, Aufnahmegebühren, Vergünstigungen und deren Änderung  bestimmt die Generalversammlung mit einfacher Stimmenmehrheit.
    Ehrenmitglieder sind beitragsfrei.
    Für Jugendliche, Schüler, Auszubildende und Wehrpflichtige können niedrigere Beiträge festgesetzt werden.
    Über Beitragsermäßigung, bzw. Beitragsaussetzung in besonders gelagerten Fällen entscheidet der Vorstand mit einfacher Mehrheit.
    § 10 Einnahmen und Ausgaben
    Die Einnahmen des Vereins bestehen aus:
    a)    Beiträge der Mitglieder
    b)    Einnahmen aus Veranstaltungen
    C)    Spenden
    d)    Sonstige Einnahmen (Zuschüsse, Umlagen bis zum Zweifachen des Familienbeitrags, etc.)
    Die Ausgaben bestehen aus:
    a)    Ausgaben gem. § 2 (Ausgaben des Spielbetriebes)
    b)    Veranstaltungsbezogene Ausgaben
    c)    Sonstige Ausgaben
    Der  1. Vorsitzende kann über Einzelausgaben bis zur Höhe von 150, - €  verfügen, über die er bei der nächsten Vorstandsitzung zu berichten hat.
    Alle anderen Ausgaben beschließt der Vorstand.
    
    <h2>§ 11 Vermögen – Haftung</h2>
    Sämtliche Einnahmen fließen dem Vereinsvermögen zu.
    Für sämtliche Verbindlichkeiten haftet der Tennisclub Grün-Weiß Amorbach 1901 e.V. ausschließlich mit seinem Vereinsvermögen.
    
    <h2>§ 12 Organe</h2>
    Die Organe des Tennisclubs Grün-Weiß sind:
    a)    Die Generalversammlung
    b)    Die Vorstandschaft
    
    <h2>§ 13 Wahlen</h2>
    Zur  Durchführung von Wahlen ist ein Wahlausschuss zu bilden. Dieser besteht  aus drei Personen, die von der Generalversammlung gewählt werden. Ein  Mitglied es Wahlausschusses ist zum Wahlausschussleiter zu wählen.
    Für  den Vorstand gem. § 15 können nur Mitglieder gewählt werden, die in der  Generalversammlung anwesend sind, oder deren schriftliches  Einverständnis mit der ihnen zugedachten Wahl vorliegt.
    Mit Vollendung des 18. Lebensjahres haben alle Mitglieder aktives und passives Wahlrecht.
    Alle Wahlen erfolgen mit einfacher Mehrheit, wobei der 1. und 2. Vorsitzende sowie der Kassier schriftlich zu wählen sind.
    Die  übrigen Vorstandsmitglieder können mit Zustimmung der  Generalversammlung per Handzeichen (Akklamation) gewählt werden.  Schriftlich und geheim ist jedoch immer zu wählen, wenn es von einem  Mitglied ausdrücklich verlangt wird.
    
    <h2>§ 14 Generalversammlung (GV)</h2>
    Die  GV ist das höchste Gremium des Vereins. Sie ist jährlich spätestens  drei Monate nach Schluss des Geschäftsjahres einzuberufen.
    Eine  Einberufung erfolgt auf Beschluss des Vorstandes eine Woche vorher und  ist unter Angabe der Tagesordnungspunkte im Amts-und Mitteilungsblatt  der Stadt Amorbach  zu veröffentlichen.
    Regelmäßige Gegenstände von Beratung und Beschlussfassung der ordentlichen GV sind:
    a)    Bericht des 1. Vorsitzenden
    b)    Bericht des Kassiers
    c)    Bericht des Sportwartes
    d)    Bericht der Kassenprüfer und Entlastung
    e)    Neuwahlen
    f)    Änderung von Satzung und Beiträgen
    g)    Wünsche und Anträge. Diese sind mindestens einen Tag vor der GV schriftlich beim Vorstand einzureichen.
    Die  in der GV gefassten Beschlüsse sind schriftlich niederzulegen und vom  Versammlungsleiter und dem Schriftführer zu unterzeichnen.
    Eine  außerordentliche GV ist einzuberufen, wenn mindestens 30 Prozent der  Mitglieder einen entsprechenden schriftlichen Antrag unter Angabe von  Grund und Ursache beim Vorstand einreichen.
    
    <h2>§ 15 Der Vorstand</h2>
    Die Vereinsgeschäfte leitet der Vorstand. Er besteht aus:
    a)    1. Vorsitzender
    b)    2. Vorsitzender
    c)    Kassier
    d)    Schriftführer
    e)    Sportwart
    f)    Frauenwart
    g)    Jugendwart
    h)    Zwei Beisitzer
    Der Vorstand wird alle zwei Jahre von der ordentlichen GV gewählt. Er bleibt bis zur Wahl eines neuen Vorstandes im Amt.
    
    <h2>§ 16 Aufgaben und Befugnisse des Vorstandes</h2>
    Der Vorstand ist bei Anwesenheit von vier Mitgliedern, darunter jedoch mindestens der 1. oder 2. Vorsitzende, beschlussfähig. (Ausnahme: § 8 – Ausschluss)
    Scheidet  ein Vorstandsmitglied während seiner Amtszeit aus, so bestimmt der  Vorstand einen kommissarischen Nachfolger mit vollem Stimmrecht.
    Der Vorstand entscheidet mit einfacher Stimmenmehrheit. Bei Stimmengleichheit gilt ein Antrag als abgelehnt.
    Namentlich oder geheim ist abzustimmen, wenn dies von einem Mitglied gefordert wird.
    Vorstand  im Sinne des BGB sind der 1. und 2. Vorsitzende und der Kassier.  Jeweils zwei vertreten gemeinsam den Verein gerichtlich und  außergerichtlich.
    Der 1.  Vorsitzende leitet den Verein und verwaltet das Vereinsvermögen. Er  leitet alle Versammlungen und Sitzungen und beruft die Sitzungen des  Vorstandes ein. Er wacht über die Ausführung von Beschlüssen der GV und  des Vorstandes und kann in Angelegenheiten, die im Interesse des Vereins  keinen Aufschub dulden, verbindliche Erklärungen abgeben. Darüber hat  er dem Vorstand in der nächsten Sitzung umfassend zu berichten.
    Bei Verhinderung des 1. Vorsitzenden nimmt dessen Befugnisse der 2. Vorsitzende wahr.
    Eine Vorstandssitzung ist binnen einer Woche einzuberufen, wenn mindesten fünf Vorstandsmitglieder dies beantragen.
    Der  Vorstand beschließt eine Platz- und Hausordnung, die die Benutzung,  Bewirtschaftung und Pflege der gesamten Tennisanlage regelt. Diese  Ordnung ist für alle Mitglieder bindend. Darüber hinaus erarbeitet der  Vorstand eine Spielordnung, die die Platzbelegung regelt. Diese  Spielordnung ist der GV zur Genehmigung vorzulegen.
    Der  Vorstand nimmt alle im Interesse des Vereins liegenden Aufgaben wahr  und trifft sämtliche Regelungen, die nicht ausdrücklich im  Zuständigkeitsbereich der GV liegen.
    Der  Kassier führt die Kassengeschäfte des Vereins und führt ordnungsgemäß  Buch über sämtliche Einnahmen und Ausgaben. Er hat der GV einen  Kassenbericht zu erstatten. Dem 1. Vorsitzenden hat er auf Anforderung  jederzeit Rechenschaft abzulegen.
    Der  Schriftführer hat über alle Sitzungen und Versammlungen ein Protokoll  zu fertigen und jedem Vorstandsmitglied eine Ausfertigung auszuhändigen.  Ihm obliegt die Abfassung aller erforderlichen Schriftstücke, die durch  die Vereinsarbeit anfallen. Die Abzeichnung erfolgt abschließend durch  den 1. Vorsitzenden.
    
    <h2>§ 17 Ausschüsse</h2>
    Der  Vorstand ist berechtigt, Ausschüsse auf Zeit oder Dauer zu bilden,  sowie Einzelpersonen mit Sonderaufgaben zu betrauen. Ausschüsse und  Einzelpersonen können zu Vorstandssitzungen beigeladen werden, haben  jedoch kein Stimmrecht.
    
    <h2>§ 18 Kassenprüfer</h2>
    Vor  jeder ordentlichen GV sind Kasse, Bücher und Belege von zwei  Kassenprüfern, die Mitglieder des Tennisclubs sein müssen, zu prüfen.  Hierüber ist der GV Bericht zu erstatten. Die Kassenprüfer werden von  der GV gewählt. Sie dürfen nicht Mitglieder des Vorstandes sein.
    
    <h2>§ 19 Auflösung</h2>
    Die  Auflösung des Tennisclubs Grün-Weiß Amorbach 1901 e.V. kann nur mit 3 /  4 der Stimmen der anwesenden Mitglieder der GV beschlossen werden. Der  Verein ist jedoch aufzulösen, wenn die GV dies einstimmig beschließt.
    Nach  Auflösung des Tennisclubs oder bei Wegfall steuerbegünstigter Zwecke  fällt das Vermögen des Vereins der Stadt Amorbach zu mit der Auflage, es  nur für die in § 2 dieser Satzung und unmittelbar und ausschließlich  gemeinnützig festgelegten Zwecke zu verwenden.
    
    <h2>§ 20 Inkrafttreten</h2>
    Diese  Satzung ist von der GV am 3.3.2016 einstimmig beschlossen worden.  Sollten einzelne Bestimmungen rechtsunwirksam sein, so sind  nur diese, nicht jedoch die gesamte Satzung ungültig.
    Rechtsunwirksame Bestimmungen sind so zu interpretieren, dass sie dem Sinn und Zweck dieser Satzung entsprechen.
    Änderungen können nur durch die GV erfolgen.
    </pre> 
</div>
